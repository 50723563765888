import { useCallback, useEffect, useState } from 'react';
import { GridRowParams, GridSortModel } from '@mui/x-data-grid-premium';
import { SustainabilityReportOptions } from 'src/shared/services/api/options/sustainabilityReportOptions';
import { BusinessAreaResponse } from 'src/shared/services/api/response/businessAreasResponse';
import { SustainabilityAPI } from 'src/shared/services/api/sustainabilityAPI';
import { useIsMounted } from 'src/shared/helpers/hooks/useIsMounted';
import { SustainabilityReportResponse } from 'src/shared/services/api/response/sustainabilityReportResponse';
import ConsoleHelper from 'src/shared/helpers/consoleHelper';
import { TagsResponse } from 'src/shared/services/api/response/tagsResponse';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { RoutesEnum } from 'src/shared/enums/routes.enum';
import { ProjectStatusEnum } from 'src/shared/enums/projectStatus.enum';
import { Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { RootStateType } from 'src/shared/services/redux/reducers';
import { useLocalStorage } from 'src/shared/helpers/hooks/useLocalStorage';
import { LocalStorageKeysEnum } from 'src/shared/enums/localStorageKeys.enum';
import dayjs from 'dayjs';
import { SustainabilityDataGrid } from './sustainabilityDataGrid';
import {
  ProjectStatusFilter,
  SustainabilityFilter,
} from './sustainabilityFilter';

type SustainabilityReportProjectProps = {
  refetchList: Date | undefined;
};

export const SustainabilityReportProject = (
  props: SustainabilityReportProjectProps,
) => {
  const { refetchList } = props;
  const isMounted = useIsMounted();
  const history = useHistory();
  const { t } = useTranslation();

  const { userCompany } = useSelector(
    (state: RootStateType) => state.authState,
  );

  const [loading, setLoading] = useState<boolean>(true);
  const [data, setData] = useState<SustainabilityReportResponse[]>([]);
  const [totalItems, setTotalItems] = useState<number>(0);

  const [businessArea, setBusinessArea] = useLocalStorage<
    BusinessAreaResponse | undefined
  >(LocalStorageKeysEnum.SustainabilityReportProjectBusinessArea, undefined);
  const [locationTag, setLocationTag] = useLocalStorage<
    TagsResponse | undefined
  >(LocalStorageKeysEnum.SustainabilityReportProjectTagsResponse, undefined);
  const [projectStatusFilter, setProjectStatusFilter] = useLocalStorage<
    ProjectStatusFilter | undefined
  >(
    LocalStorageKeysEnum.SustainabilityReportProjectProjectStatusFilter,
    undefined,
  );

  const [requestOptions, setRequestOptions] =
    useState<SustainabilityReportOptions>({
      pageIndex: 0,
      pageSize: 100,
      sortCol: 'created',
      sortDir: 'desc',
      locationTagId: locationTag?.id,
      businessAreaID: businessArea?.id,
      companyID: userCompany?.id,
      projectStatus: [
        ProjectStatusEnum.Created,
        ProjectStatusEnum.ReadyForApproval,
        ProjectStatusEnum.Denied,
        ProjectStatusEnum.SupplementRequested,
      ],
      projectStatusFilter: projectStatusFilter?.id ?? ProjectStatusEnum.All,
    });

  // Fetch sustainability reports
  const fetchSustainabilityReports = useCallback(async () => {
    setLoading(true);
    const api = new SustainabilityAPI();
    try {
      const res = await api.GetAll(requestOptions);
      if (isMounted()) {
        setData(res.results ?? []);
        setTotalItems(res.totalItems ?? 0);
      }
    } catch (error: any) {
      ConsoleHelper.log('Error fetching reports:', error);
      if (error.status === 404) {
        setData([]);
        setTotalItems(0);
      }
    } finally {
      if (isMounted()) {
        setLoading(false);
      }
    }
  }, [isMounted, requestOptions]);

  // Sync requestOptions when local storage changes
  useEffect(() => {
    setRequestOptions((prev) => ({
      ...prev,
      locationTagId: locationTag?.id,
      businessAreaID: businessArea?.id,
      projectStatusFilter: projectStatusFilter?.id ?? ProjectStatusEnum.All,
    }));
  }, [businessArea?.id, locationTag?.id, projectStatusFilter?.id]);

  // Trigger fetch on requestOptions change or refetch
  useEffect(() => {
    fetchSustainabilityReports();
  }, [fetchSustainabilityReports, refetchList]);

  // Handle page change
  const onPageChange = (newPage: number) => {
    setRequestOptions((prev) => ({
      ...prev,
      pageIndex: newPage,
    }));
  };

  // Handle page size change
  const onPageSizeChange = (size: number) => {
    setRequestOptions((prev) => ({
      ...prev,
      pageSize: size,
    }));
  };

  // Handle sort model change
  const onSortModelChanged = (model: GridSortModel) => {
    if (model.length > 0) {
      setRequestOptions((prev) => ({
        ...prev,
        sortCol: model[0].field,
        sortDir: model[0].sort as string,
      }));
    }
  };

  // Handle row click
  const onRowClickHandler = (params: GridRowParams) => {
    history.push(
      `${RoutesEnum.SustainabilityReportDetailsPage}/${params.row.id}`,
    );
  };

  return (
    <>
      <Typography mb={2}>{t('sustainabilityReportInfo')}</Typography>
      <SustainabilityFilter
        businessArea={businessArea}
        setBusinessArea={setBusinessArea}
        locationTag={locationTag}
        setLocations={setLocationTag}
        projectStatusFilter={projectStatusFilter}
        setProjectStatusFilter={setProjectStatusFilter}
      />
      <SustainabilityDataGrid
        loading={loading}
        data={data ?? []}
        totalRows={totalItems}
        onPageChange={onPageChange}
        onPageSizeChange={onPageSizeChange}
        onSortModelChanged={onSortModelChanged}
        page={requestOptions.pageIndex ?? 0}
        size={requestOptions.pageSize ?? 100}
        onRowClick={onRowClickHandler}
      />
    </>
  );
};
