/* eslint-disable no-nested-ternary */
import { Box, Button, LinearProgress, Tab, Typography } from '@mui/material';
import { PageTemplate } from 'src/shared/templates/pageTemplate';
import { useHistory, useParams } from 'react-router-dom';
import { IParams } from 'src/shared/interfaces/params';
import { useFetchApproverUsers } from 'src/shared/helpers/hooks/useFetchApproverUsers';
import { useFetchTags } from 'src/shared/helpers/hooks/useFetchTags';
import { useFetchEmployeesByCompanyId } from 'src/shared/helpers/hooks/useFetchEmployeesByCompanyId';
import { useFetchBusinessAreas } from 'src/shared/helpers/hooks/useFetchBusinessAreas';
import { useTranslation } from 'react-i18next';
import { ArrowBack, ErrorOutlineOutlined } from '@mui/icons-material';
import { CompanyTypeEnum } from 'src/shared/enums/companyType.enum';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { useEffect, useMemo, useState } from 'react';
import { Colors } from 'src/shared/components/styles';
import { RoutesEnum } from 'src/shared/enums/routes.enum';
import { useClearLocalStorage } from 'src/shared/helpers/hooks/useClearFormState';
import { useFetchSustainabilityReportById } from '../hooks/useFetchSustainabilityReportById';
import { SustainabilityEditForm } from '../components/sustainabilityEditForm';
import { SustainabilityStatusBadge } from '../components/SustainabilityStatusBadge';
import { SustainabilityMeetingPlanForm } from '../components/sustainabilityMeetingPlanForm';
import { SustainabilityCustomerSurveyForm } from '../components/sustainabilityCustomerSurveyForm';
import { SustainabilityLog } from '../components/sustainabilityLog';

const TabByType = [
  {
    companyType: CompanyTypeEnum.Installation,
    tabs: [
      { value: 'sustainabilityReport', label: 'tabs.SustainabilityReport' },
      {
        value: 'sustainabilityLog',
        label: 'tabs.SustainabilityLog',
      },
    ],
  },
  {
    companyType: CompanyTypeEnum.Teknikkonsult,
    tabs: [
      { value: 'sustainabilityReport', label: 'tabs.SustainabilityReport' },
      { value: 'meetingPlan', label: 'tabs.MeetingPlan' },
      { value: 'customerSurvey', label: 'tabs.CustomerSurvey' },
      { value: 'sustainabilityLog', label: 'tabs.SustainabilityLog' },
    ],
  },
  {
    companyType: CompanyTypeEnum.Industri,
    tabs: [
      { value: 'sustainabilityReport', label: 'tabs.SustainabilityReport' },
      { value: 'customerSurvey', label: 'tabs.CustomerSurvey' },
      { value: 'sustainabilityLog', label: 'tabs.SustainabilityLog' },
    ],
  },
];

export const SustainabilityReportDetailsPage = () => {
  const { id } = useParams<IParams>();
  const { t } = useTranslation();
  const history = useHistory();
  const { clearLocalStorage } = useClearLocalStorage();

  const [tabValue, setTabValue] = useState('sustainabilityReport');

  const { reportData, refetchReport, reportLoading } =
    useFetchSustainabilityReportById(id);
  const { businessAreasData } = useFetchBusinessAreas();
  const { employeesData, employeesDataLoading } =
    useFetchEmployeesByCompanyId('');
  const { tagData } = useFetchTags('Location');
  const { aosData } = useFetchApproverUsers();

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setTabValue(newValue);
  };

  const refetchHandler = () => {
    setTabValue('sustainabilityReport');
    refetchReport();
    window.scrollTo(0, 0);
  };

  const refetchAfterUpdateHandler = () => {
    history.push(`${RoutesEnum.SustainabilityReportListPage}/3304`); // Local id Oliver 3258 // dev 3304
    clearLocalStorage(`sustainabilityReport-${id}`);
  };

  const goBackHandler = () => {
    history.goBack();
    clearLocalStorage(`sustainabilityReport-${id}`);
    clearLocalStorage(`meetingPlan-${id}`);
    clearLocalStorage(`customerSurvey-${id}`);
  };

  const companyType = reportData?.typeOfCompany ?? CompanyTypeEnum.Installation;

  // Memoize TabByType with translated labels
  const translatedTabs = useMemo(() => {
    return TabByType.map((item) => ({
      companyType: item.companyType,
      tabs: item.tabs.map((tab) => ({
        ...tab,
        label: t(tab.label), // Translate the label here
      })),
    }));
  }, [t]);

  // Get tabs for the current company type
  const companyTabs =
    translatedTabs.find((item) => item.companyType === companyType)?.tabs || [];

  return (
    <PageTemplate
      content={
        <Box p={1}>
          <Box
            display='flex'
            flexDirection='row'
            justifyContent='space-between'
            alignItems='center'
          >
            <Button
              variant='text'
              sx={{ fontSize: 16 }}
              startIcon={<ArrowBack />}
              onClick={goBackHandler}
            >
              {t('goBack')}
            </Button>
            <SustainabilityStatusBadge
              status={reportData?.projectStatus ?? 0}
            />
          </Box>
          <Box
            display='flex'
            flexDirection='row'
            justifyContent='space-between'
            alignItems='center'
            p={1}
          >
            <Typography fontSize={18} fontWeight={500}>
              {`${reportData?.project}: ${reportData?.projectNumber}`}
            </Typography>
          </Box>
          <TabContext value={tabValue}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider', m: 1 }}>
              <TabList
                onChange={handleTabChange}
                aria-label='SustainabilityReportListPageTab'
              >
                {companyTabs.map((tab) => (
                  <Tab
                    key={tab.value}
                    label={
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Box mr={1}>{t(tab.label)}</Box>

                        {tab.value === 'meetingPlan' &&
                        !reportData?.meetingPlan ? (
                          <ErrorOutlineOutlined
                            sx={{ color: Colors.BADGE_RED }}
                          />
                        ) : tab.value === 'customerSurvey' &&
                          !reportData?.customerSurvey ? (
                          <ErrorOutlineOutlined
                            sx={{ color: Colors.BADGE_RED }}
                          />
                        ) : null}
                      </Box>
                    }
                    value={tab.value}
                    sx={{
                      fontWeight: 700,
                    }}
                  />
                ))}
              </TabList>
            </Box>
            <TabPanel sx={{ p: 0 }} value='sustainabilityReport'>
              {(employeesDataLoading || reportLoading) && <LinearProgress />}
              {reportData &&
                businessAreasData &&
                tagData &&
                employeesData &&
                aosData && (
                  <SustainabilityEditForm
                    item={reportData}
                    businessAreasData={businessAreasData}
                    aosData={aosData}
                    tagData={tagData}
                    employeesData={employeesData}
                    refetchAfterUpdate={refetchAfterUpdateHandler}
                  />
                )}
            </TabPanel>
            <TabPanel sx={{ p: 4 }} value='meetingPlan'>
              {reportData && (
                <SustainabilityMeetingPlanForm
                  refetchCallback={refetchHandler}
                  meetingPlan={reportData && reportData?.meetingPlan}
                  sustainabilityId={reportData && reportData?.id}
                />
              )}
            </TabPanel>
            <TabPanel sx={{ p: 4 }} value='customerSurvey'>
              {reportData && (
                <SustainabilityCustomerSurveyForm
                  refetchCallback={refetchHandler}
                  customerSurvey={reportData && reportData?.customerSurvey}
                  sustainabilityId={reportData && reportData?.id}
                />
              )}
            </TabPanel>
            <TabPanel sx={{ p: 4 }} value='sustainabilityLog'>
              {reportData && <SustainabilityLog logData={reportData.log} />}
            </TabPanel>
          </TabContext>
        </Box>
      }
    />
  );
};
