/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  GridCallbackDetails,
  GridColDef,
  GridRowParams,
  GridSortModel,
} from '@mui/x-data-grid-premium';
import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { SustainabilityReportResponse } from 'src/shared/services/api/response/sustainabilityReportResponse';
import { useTranslation } from 'react-i18next';
import { useFetchBusinessAreas } from 'src/shared/helpers/hooks/useFetchBusinessAreas';
import { useFetchTags } from 'src/shared/helpers/hooks/useFetchTags';
import { useMobile } from 'src/shared/helpers/hooks/useMobile';
import {
  DataGridPremium,
  DataGridOptions,
} from 'src/shared/components/organisms/dataGridPremium';
import { ProjectStatusEnum } from 'src/shared/enums/projectStatus.enum';
import { CompanyTypeEnum } from 'src/shared/enums/companyType.enum';
import { SustainabilityStatusBadge } from './SustainabilityStatusBadge';

type SustainabilityDataGridProps = {
  data: SustainabilityReportResponse[];
  loading: boolean;
  totalRows: number;
  onPageChange: (index: number) => void;
  onPageSizeChange: (size: number) => void;
  onSortModelChanged: (
    model: GridSortModel,
    details: GridCallbackDetails,
  ) => void | undefined;
  page: number;
  size: number;
  onRowClick: (params: GridRowParams) => void;
};

export const SustainabilityDataGrid = (props: SustainabilityDataGridProps) => {
  const {
    data,
    totalRows,
    onPageChange,
    onPageSizeChange,
    onSortModelChanged,
    page,
    size,
    onRowClick,
    loading,
  } = props;
  const { t } = useTranslation();

  const { isMobileSm, isMobileLg } = useMobile();

  const { businessAreasData } = useFetchBusinessAreas();
  const { tagData } = useFetchTags('Location');

  const [dataGridOptions, setDataGridOptions] = useState<DataGridOptions>({
    loading,
    rowsPerPageOptions: undefined,
    totalRows,
  });

  const columns: GridColDef[] = [
    {
      field: 'project',
      headerName: t('project'),
      flex: 1.5,
    },
    {
      field: 'projectNumber',
      headerName: t('nr'),
      hide: true,
      flex: 1,
    },
    {
      field: 'companyName',
      headerName: t('company'),
      sortable: false,
      flex: 1.5,
    },
    {
      field: 'customer',
      headerName: t('customer'),
      hide: isMobileSm,
      flex: 1.5,
    },

    {
      field: 'businessAreaId',
      headerName: t('businessArea'),
      valueGetter: (params) => {
        const businessArea = businessAreasData?.find(
          (area) => area.id === params.row.businessAreaId,
        )?.name;
        return businessArea;
      },
      renderCell: (params) => {
        const businessArea = businessAreasData?.find(
          (area) => area.id === params.row.businessAreaId,
        )?.name;
        return businessArea;
      },
    },
    {
      field: 'locationTagId',
      headerName: t('BusinessArea'),
      valueGetter: (params) => {
        const location = tagData?.find(
          (locationName) => locationName.id === params.row.locationTagId,
        )?.name;
        return location;
      },
      renderCell: (params) => {
        const location = tagData?.find(
          (locationName) => locationName.id === params.row.locationTagId,
        )?.name;
        return location;
      },
    },
    {
      field: 'changed',
      headerName: t('updated'),
      hide: true,
      flex: 1,
      valueGetter: (params) => {
        if (params.row.changed) {
          return dayjs(params.row.changed).format('D MMM YYYY');
        }
        return dayjs(params.row.created).format('D MMM YYYY');
      },
      renderCell: (params) => {
        if (params.row.changed) {
          return dayjs(params.row.changed).format('D MMM YYYY');
        }
        return dayjs(params.row.created).format('D MMM YYYY');
      },
    },
    {
      field: 'projectStatus',
      headerName: t('status'),
      flex: isMobileLg ? 1 : 2,
      valueGetter: (params) => {
        switch (params.row.projectStatus) {
          case ProjectStatusEnum.Approved:
            return t('approved');
          case ProjectStatusEnum.Created:
            return t('reported');
          case ProjectStatusEnum.ReadyForApproval:
            return t('readyForApproval');
          case ProjectStatusEnum.SupplementRequested:
            return t('supplementRequested');
          case ProjectStatusEnum.Denied:
            return t('denied');
          default:
            return '';
        }
      },
      renderCell: (params) => {
        return <SustainabilityStatusBadge status={params.row.projectStatus} />;
      },
    },
    {
      field: 'reporterUser.personName',
      valueGetter: (params) => params.row.reporterUser.personName,
      headerName: t('reportedBy'),
      sortable: false,
      flex: 1.5,
      hide: isMobileSm,
    },
    {
      field: 'approverUser',
      headerName: t('approverUser'),
      valueGetter: (params) => params.row.approverUser.personName,
      hide: true,
    },
    {
      field: 'created',
      headerName: t('created'),
      hide: isMobileSm,
      flex: 1,
      valueGetter: (params) => dayjs(params.row.created).format('D MMM YYYY'),
      renderCell: (params) => dayjs(params.row.created).format('D MMM YYYY'),
    },
    {
      field: 'registrationDate',
      headerName: t('registrationDate'),
      hide: true,
      valueGetter: (params) =>
        dayjs(params.row.registrationDate).format('D MMM YYYY'),
      renderCell: (params) =>
        dayjs(params.row.registrationDate).format('D MMM YYYY'),
    },
    {
      field: 'projectEndDate',
      headerName: t('projectEndDate'),
      hide: true,
      valueGetter: (params) =>
        params.row.projectEndDate
          ? dayjs(params.row.projectEndDate).format('D MMM YYYY')
          : '',
      renderCell: (params) =>
        params.row.projectEndDate
          ? dayjs(params.row.projectEndDate).format('D MMM YYYY')
          : '',
    },
    {
      field: 'approvalDate',
      headerName: t('approved'),
      hide: true,
      valueGetter: (params) =>
        dayjs(params.row.approvalDate).format('D MMM YYYY'),
      renderCell: (params) =>
        dayjs(params.row.approvalDate).format('D MMM YYYY'),
    },
    {
      field: 'typeOfCompany',
      headerName: t('typeOfCompany'),
      valueGetter: (params) => {
        switch (params.row.typeOfCompany) {
          case CompanyTypeEnum.Teknikkonsult:
            return t('sustainabilityReportTypeOfCompany.technicalConsult');
          case CompanyTypeEnum.Installation:
            return t('sustainabilityReportTypeOfCompany.installation');
          case CompanyTypeEnum.Industri:
            return t('sustainabilityReportTypeOfCompany.industry');
          default:
            return '';
        }
      },
      renderCell: (params) => {
        switch (params.row.typeOfCompany) {
          case CompanyTypeEnum.Teknikkonsult:
            return t('sustainabilityReportTypeOfCompany.technicalConsult');
          case CompanyTypeEnum.Installation:
            return t('sustainabilityReportTypeOfCompany.installation');
          case CompanyTypeEnum.Industri:
            return t('sustainabilityReportTypeOfCompany.industry');
          default:
            return '';
        }
      },
      hide: true,
    },
    {
      field: 'invoicedAmount',
      headerName: t('invoicedAmount'),
      valueGetter: (params) => params.row.invoicedAmount,
      hide: true,
    },
    {
      field: 'projectDescription',
      headerName: t('projectDescription'),
      valueGetter: (params) => params.row.projectDescription,
      hide: true,
    },
    {
      field: 'climateBenefitDescription',
      headerName: t('climateBenefitDescription'),
      valueGetter: (params) => params.row.climateBenefitDescription,
      hide: true,
    },
    {
      field: 'sustainabilitySolutionTechDescription',
      headerName: t('sustainabilitySolutionTechDescription'),
      valueGetter: (params) => params.row.sustainabilitySolutionTechDescription,
      hide: true,
    },
  ];

  useEffect(() => {
    setDataGridOptions((prev) => ({
      ...prev,
      loading,
      totalRows,
    }));
  }, [loading, totalRows]);

  return (
    <>
      <DataGridPremium<SustainabilityReportResponse>
        columns={columns}
        rows={data}
        dataGridOptions={dataGridOptions}
        onPageChange={onPageChange}
        onPageSizeChange={onPageSizeChange}
        onSortModelChange={onSortModelChanged}
        checkboxSelection={false}
        page={page}
        pageSize={size}
        disableSelectionOnClick
        onRowClick={(row) => onRowClick(row)}
      />
    </>
  );
};
