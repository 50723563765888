import {
  DataGridPremium as DataGrid,
  GridColDef,
  GridSortModel,
  GridCallbackDetails,
  GridToolbarContainer,
  GridRowParams,
  GridToolbarExport,
  useGridApiRef,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
} from '@mui/x-data-grid-premium';
import { Box, CircularProgress, Paper, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { Colors } from '../styles';

export type DataGridOptions = {
  loading: boolean;
  rowsPerPageOptions?: number[];
  totalRows: number;
};

export type DataGridProps<T> = {
  columns: GridColDef[];
  rows: T[];
  page: number;
  pageSize: number;
  dataGridOptions: DataGridOptions;
  checkboxSelection: boolean;
  onPageChange: (pageIndex: number) => void;
  onPageSizeChange: (pageSize: number) => void;
  onSortModelChange: (
    model: GridSortModel,
    details: GridCallbackDetails,
  ) => void | undefined;
  disableSelectionOnClick?: boolean;
  onRowClick?: (params: GridRowParams) => void;
};

/* function CustomMobileToolbar() {
  return (
    <GridToolbarContainer>
      // <GridToolbarColumnsButton />
      // <GridToolbarFilterButton /> 
    </GridToolbarContainer>
  );
} */

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      {/* Standardfunktioner */}
      <GridToolbarColumnsButton
        nonce={undefined}
        onResize={undefined}
        onResizeCapture={undefined}
      />
      <GridToolbarFilterButton
        nonce={undefined}
        onResize={undefined}
        onResizeCapture={undefined}
      />
      <GridToolbarDensitySelector
        nonce={undefined}
        onResize={undefined}
        onResizeCapture={undefined}
      />
      {/* Exportfunktioner */}
      <GridToolbarExport
        csvOptions={{
          allColumns: true, // Exportera alla kolumner
          fileName: 'sustainability_reports', // Anpassa filnamnet
        }}
        excelOptions={{
          allColumns: true, // Exportera alla kolumner
          includeHeaders: true, // Inkludera kolumnrubriker
          includeHiddenColumns: true, // Inkludera dolda kolumner
          fileName: 'sustainability_report', // Anpassa filnamnet
        }}
      />
    </GridToolbarContainer>
  );
}

function CustomLoadingOverlay() {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        backgroundColor: Colors.WHITE,
      }}
    >
      <CircularProgress />
    </Box>
  );
}

export const DataGridPremium = <T,>(props: DataGridProps<T>) => {
  const {
    columns,
    rows,
    page,
    pageSize,
    dataGridOptions: { loading, totalRows },
    checkboxSelection,
    onPageChange,
    onPageSizeChange,
    onSortModelChange,
    disableSelectionOnClick,
    onRowClick,
  } = props;

  const apiRef = useGridApiRef();

  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Paper
      sx={{
        height: isMobile ? '80vh' : '60vh',
        boxShadow: 'none',
        overflow: 'auto',
        '.MuiDataGrid-root': { border: 'none', fontSize: 12 },
        '.MuiDataGrid-footerContainer': {
          borderTop: '1px solid lightgray',
        },
        '.MuiDataGrid-overlay': {
          backgroundColor: Colors.WHITE,
          fontSize: 16,
        },
      }}
    >
      <DataGrid
        apiRef={apiRef}
        rows={rows}
        columns={columns}
        density='standard'
        rowCount={totalRows}
        rowsPerPageOptions={[25, 50, 100, 300, 500]}
        checkboxSelection={checkboxSelection}
        paginationMode='server'
        page={page}
        pageSize={pageSize}
        loading={loading}
        onPageChange={(newPage) => onPageChange(newPage)}
        onPageSizeChange={(newPageSize) => onPageSizeChange(newPageSize)}
        onSortModelChange={onSortModelChange}
        filterMode='server'
        sortingMode='server'
        disableColumnFilter
        pagination
        disableSelectionOnClick={disableSelectionOnClick}
        onRowClick={onRowClick}
        localeText={{
          columnsPanelTextFieldPlaceholder: t('datagrid.columnsPanelTextField'),
          columnsPanelTextFieldLabel: t('datagrid.columnsPanelTextFieldLabel'),
          columnsPanelShowAllButton: t('datagrid.columnsPanelShowAllButton'),
          columnsPanelHideAllButton: t('datagrid.columnsPanelHideAllButton'),
          toolbarColumns: t('datagrid.toolbarColumns'),
          toolbarDensity: t('datagrid.toolbarDensity'),
          toolbarDensityLabel: t('datagrid.toolbarDensityLabel'),
          toolbarDensityCompact: t('datagrid.toolbarDensityCompact'),
          toolbarDensityStandard: t('datagrid.toolbarDensityStandard'),
          toolbarDensityComfortable: t('datagrid.toolbarDensityComfortable'),
          toolbarExport: t('datagrid.toolbarExport'),
          toolbarExportPrint: t('datagrid.toolbarExportPrint'),
          toolbarExportExcel: t('datagrid.toolbarExportExcel'),
          toolbarExportCSV: t('datagrid.toolbarExportCSV'),
          toolbarFilters: t('datagrid.toolbarFilters'),
          columnMenuSortAsc: t('datagrid.columnMenuSortAsc'),
          columnMenuSortDesc: t('datagrid.columnMenuSortDesc'),
          columnMenuHideColumn: t('datagrid.columnMenuHideColumn'),
          columnMenuShowColumns: t('datagrid.columnMenuShowColumns'),
          columnMenuLabel: t('datagrid.columnMenuLabel'),
          columnMenuFilter: t('datagrid.columnMenuFilter'),
          columnMenuUnsort: t('datagrid.columnMenuUnsort'),
          noRowsLabel: t('datagrid.noRowsLabel'),
        }}
        components={{
          LoadingOverlay: CustomLoadingOverlay,
          Toolbar: CustomToolbar,
        }}
        // componentsProps={{
        //   loadingOverlay: CustomLoadingOverlay,
        //   toolbar: CustomToolbar, // isMobile ? CustomMobileToolbar : GridToolbar,
        // }}
        sx={{
          // '& .MuiDataGrid-row:hover': {
          //   cursor: 'pointer',
          // },
          '& .MuiDataGrid-cell:focus': {
            outline: 'none',
          },
          '& .MuiDataGrid-row.Mui-selected': {
            backgroundColor: 'inherit',
          },
        }}
      />
    </Paper>
  );
};

DataGridPremium.defaultProps = {
  disableSelectionOnClick: false,
  onRowClick: undefined,
};
