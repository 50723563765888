import React, { useState } from 'react';
import ConsoleHelper from '../consoleHelper';

function getStorageValue<T>(key: string, defaultValue: T): T {
  if (typeof window !== 'undefined' && window.localStorage) {
    const saved = localStorage.getItem(key);
    if (saved !== null && saved !== 'undefined') {
      try {
        return JSON.parse(saved) as T;
      } catch (error) {
        ConsoleHelper.log(`Error parsing localStorage key "${key}":`, error);
      }
    } else if (saved === 'undefined') {
      ConsoleHelper.log(
        `localStorage key "${key}" contains an invalid value: "undefined". Resetting to default.`,
      );
    }
  }
  return defaultValue;
}

export const useLocalStorage = <T>(
  localStorageKey: string,
  defaultValue: T,
): [T, React.Dispatch<React.SetStateAction<T>>] => {
  const [value, setValue] = useState<T>(() => {
    return getStorageValue<T>(localStorageKey, defaultValue);
  });

  React.useEffect(() => {
    const currentValue = getStorageValue<T>(localStorageKey, defaultValue);
    if (value !== currentValue) {
      try {
        localStorage.setItem(localStorageKey, JSON.stringify(value));
      } catch (error) {
        ConsoleHelper.log(
          `Failed to set localStorage key "${localStorageKey}" with value:`,
          error,
        );
      }
    }
  }, [defaultValue, localStorageKey, value]);

  return [value, setValue];
};
