import { Button, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import AutoCompleteForFilter from 'src/modules/employee/components/autoCompleteForFilter';
import { useFetchBusinessAreas } from 'src/shared/helpers/hooks/useFetchBusinessAreas';
import { useFetchTags } from 'src/shared/helpers/hooks/useFetchTags';
import { BusinessAreaResponse } from 'src/shared/services/api/response/businessAreasResponse';
import { TagsResponse } from 'src/shared/services/api/response/tagsResponse';

export interface ProjectStatusFilter {
  id: number;
  name: string;
}

const statusData = (t: (n: string) => string): ProjectStatusFilter[] => [
  { id: 1, name: t('reported') },
  { id: 2, name: t('readyForApproval') },
  { id: 4, name: t('denied') },
  { id: 5, name: t('supplementRequested') },
];

type SustainabilityFilterProps = {
  locationTag?: TagsResponse | undefined;
  setLocations?: (arg: TagsResponse | undefined) => void;
  businessArea?: BusinessAreaResponse | undefined;
  setBusinessArea?: (arg: BusinessAreaResponse | undefined) => void;
  projectStatusFilter?: ProjectStatusFilter | undefined;
  setProjectStatusFilter?: (arg: ProjectStatusFilter | undefined) => void;
  year?: ProjectStatusFilter | undefined;
  setYear?: (arg: ProjectStatusFilter | undefined) => void;
};

export const SustainabilityFilter = (props: SustainabilityFilterProps) => {
  const {
    locationTag,
    setLocations,
    businessArea,
    setBusinessArea,
    projectStatusFilter,
    setProjectStatusFilter,
    year,
    setYear,
  } = props;
  const { t } = useTranslation();

  // FETCH DATA
  const { businessAreasData } = useFetchBusinessAreas();
  const { tagData } = useFetchTags('Location');

  const generateYears = (startYear: number): ProjectStatusFilter[] => {
    const currentYear = new Date().getFullYear();
    const years: ProjectStatusFilter[] = [];

    for (let y = startYear; y <= currentYear; y += 1) {
      years.push({ id: y, name: y.toString() });
    }

    return years;
  };

  const yearArray = generateYears(2024);

  return (
    <Grid container columnGap={2} alignItems='flex-end'>
      {setBusinessArea && (
        <Grid item>
          <AutoCompleteForFilter
            name='BuisnessArea'
            formLabel={t('discipline')}
            selectItems={businessAreasData ?? []}
            onChange={setBusinessArea}
            value='businessArea'
            inputValue={businessArea ? businessArea.name : ''}
            type='text'
            size='small'
            fontSize={13}
            placeholder={t('all')}
          />
        </Grid>
      )}
      {setLocations && (
        <Grid item>
          <AutoCompleteForFilter
            name='Location'
            formLabel={t('BusinessArea')}
            selectItems={tagData ?? []}
            onChange={setLocations}
            inputValue={locationTag ? locationTag.name : ''}
            value='location'
            type='text'
            size='small'
            fontSize={13}
            placeholder={t('all')}
          />
        </Grid>
      )}
      {setProjectStatusFilter && (
        <Grid item>
          <AutoCompleteForFilter
            name='ProjectStatus'
            formLabel={t('status')}
            selectItems={statusData(t) ?? []}
            onChange={setProjectStatusFilter}
            inputValue={
              projectStatusFilter ? projectStatusFilter.name.toString() : ''
            }
            value='projectStatus'
            type='text'
            size='small'
            fontSize={13}
            placeholder={t('all')}
          />
        </Grid>
      )}
      {setYear && (
        <Grid item>
          <AutoCompleteForFilter
            name='Year'
            formLabel={t('year')}
            selectItems={yearArray}
            onChange={setYear}
            inputValue={year ? year.name.toString() : ''}
            value='year'
            type='text'
            size='small'
            fontSize={13}
            placeholder={t('all')}
          />
        </Grid>
      )}
      <Grid item alignItems='flex-end'>
        <Button
          size='medium'
          variant='outlined'
          disableElevation
          sx={{ mb: 0.5 }}
          onClick={() => {
            if (setLocations) setLocations(undefined);
            if (setBusinessArea) setBusinessArea(undefined);
            if (setProjectStatusFilter) setProjectStatusFilter(undefined);
            if (setYear) setYear(undefined);
          }}
        >
          {t('reset')}
        </Button>
      </Grid>
    </Grid>
  );
};

SustainabilityFilter.defaultProps = {
  setLocations: undefined,
  setBusinessArea: undefined,
  setProjectStatusFilter: undefined,
  locationTag: undefined,
  businessArea: undefined,
  projectStatusFilter: undefined,
  year: undefined,
  setYear: undefined,
};
