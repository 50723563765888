import { AppBar, Toolbar } from '@mui/material';
import { Colors } from 'src/shared/components/styles';
import InstalcoLogSmall from 'src/assets/images/InstalcoNet.jpeg';
// import InstalcoLogSmall from 'src/assets/images/Instalco@2.png';

const AuthHeader = () => {
  return (
    <AppBar position='fixed'>
      <Toolbar
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          backgroundColor: Colors.WHITE,
        }}
      >
        <img
          src={InstalcoLogSmall}
          srcSet={InstalcoLogSmall}
          style={{
            height: '60px',
            padding: '10px 0px',
            objectFit: 'contain',
            display: 'block',
          }}
          alt='logo-instalco'
          loading='lazy'
        />
      </Toolbar>
    </AppBar>
  );
};

export default AuthHeader;
