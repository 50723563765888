import { useState, useCallback, useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import MainRoutes from 'src/shared/routes/mainRoutes';
import SilentRenewPage from 'src/modules/auth/pages/silentRenewPage';
import CallbackPage from 'src/modules/auth/pages/callbackPage ';
import { Box, Typography } from '@mui/material';
import { LicenseInfo } from '@mui/x-license-pro';
import { RoutesEnum } from './shared/enums/routes.enum';
import userManager from './shared/services/identity/userManager';
import ConsoleHelper from './shared/helpers/consoleHelper';

LicenseInfo.setLicenseKey(
  '3588807af2d4682d5f0a7bf865e50917Tz0xMDU0MzYsRT0xNzY4MDg5NTk5MDAwLFM9cHJlbWl1bSxMTT1zdWJzY3JpcHRpb24sUFY9aW5pdGlhbCxLVj0y',
);

const App = () => {
  const [loading, setLoading] = useState(false);

  const trySilentSigning = useCallback(async () => {
    setLoading(true);
    try {
      const silentResult = await userManager.signinSilent();
      ConsoleHelper.log('Silent login success', silentResult);
      setLoading(false);
    } catch (error) {
      ConsoleHelper.warn('Silent login failed');
      setLoading(false);
      // Såhär kan man göra om man automatiskt skicka användaren till identity om den inte tidigare är inloggad
      // userManager.signinRedirect();
    }
  }, []);

  useEffect(() => {
    trySilentSigning();
  }, [trySilentSigning]);

  return (
    <Router>
      <Switch>
        {loading && (
          <Box
            sx={{
              width: '100vw',
              height: '100vh',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Typography variant='h6' sx={{ color: 'text.primary' }}>
              Loading...
            </Typography>
          </Box>
        )}
        <Route exact path={RoutesEnum.CallbackPage} component={CallbackPage} />
        <Route
          exact
          path={RoutesEnum.SilentRenewPage}
          component={SilentRenewPage}
        />
        <Route path={RoutesEnum.Start} component={MainRoutes} />
      </Switch>
    </Router>
  );
};

export default App;
